import dynamic from 'next/dynamic'

import { Container, Row, Column } from './base/gridview'

const Button = dynamic(() => import('./base/button'))
const Heading = dynamic(() => import('./base/heading'))
const Html = dynamic(() => import('./base/html'))
const Section = dynamic(() => import('./base/section'))
const Image = dynamic(() => import('./base/image'))
const Video = dynamic(() => import('./base/video'))

const Header = dynamic(() => import('./widgets/header'))
const AboutUs = dynamic(() => import('./widgets/AboutUs'))
const Footer = dynamic(() => import('./widgets/footer'))
const OurServices = dynamic(() => import('./widgets/OurServices'))
const OurValues = dynamic(() => import('./widgets/OurValues'))
const HomeInfo = dynamic(() => import('./widgets/HomeInfo'))
const OurProjects = dynamic(() => import('./widgets/OurProjects'))
const OurTeam = dynamic(() => import('./widgets/OurTeam'))
const StgSlider = dynamic(() => import('./widgets/StgSlider'))
const StgDetail = dynamic(() => import('./widgets/StgDetail'))
const DetailSlider = dynamic(() => import('./widgets/DetailSlider'))

export default function getter(name: string) {
  switch (name) {
    case 'container':
      return Container
    case 'heading':
      return Heading
    case 'html':
      return Html
    case 'row':
      return Row
    case 'column':
      return Column
    case 'button':
      return Button
    case 'image':
      return Image
    case 'section':
      return Section
    case 'video':
      return Video

    case 'stg-header':
      return Header
    case 'stg-hakkimizda':
      return AboutUs

    case 'stg-hizmetlerimiz':
      return OurServices

    case 'stg-degerlerimiz':
      return OurValues

    case 'stg-ourteam':
      return OurTeam

    case 'stg-homepage-info':
      return HomeInfo
    case 'stg-ourprojects':
      return OurProjects

    case 'stg-slider':
      return StgSlider

    case 'stg-detail':
      return StgDetail

    case 'stg-detailslider':
      return DetailSlider

    case 'stg-footer':
      return Footer
  }
}
